import React from 'react';

import '../styles/component-styles/box-option.css';

const SkeletonImg = () => {
    return (
        <div className='skeleton'>
        </div>
    )
}

export default SkeletonImg;